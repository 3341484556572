.static-page {
    display: flex;
    height: 100vh;
    font-weight: 200;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
}

.static-page-body {
    width: 30em;
}

.static-page h1 {
    font-weight: 300;
}

.static-page-subtitle {
    color: #888888;
}

.category-container {
    margin: 3.4rem auto 3rem;
    z-index: 0;
    position: relative;
}

.offset {
    margin-top: 70px;
}

@media (max-width: 700px) {
    .category-container {
        margin-top: 4.4rem;
    }

    .offset {
        margin-top: 84px;
    }
}

/*.container {*/
/*  max-width: 1100px;*/
/*  margin: auto;*/
/*  overflow: hidden;*/
/*  padding: 0 2rem;*/
/*  margin-top: 6rem;*/
/*  */
/*}*/