.registration .weui-cells__title {
    margin-top: 5.5em;
}

.error {
    color: red;
    font-size: 11px;
}
/*.weui-cell__hd,*/
/*.weui-cell__ft {*/
/*    color: #4A4A4A;*/
/*}*/

/*.weui-btn_primary {*/
/*    background-color: #1aad19;*/
/*}*/

/*.container {*/
/*  max-width: 1100px;*/
/*  margin: auto;*/
/*  overflow: hidden;*/
/*  padding: 0 2rem;*/
/*  margin-top: 6rem;*/
/*  */
/*}*/